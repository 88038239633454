<template>
  <main>
    <div class="form">
      <!-- <div class="banniere">
        <img src="../assets/images/tondeuse.jpg">
        <h2>offre de noël</h2>
        <h3>Jusqu'à 350€ remboursés pour l'achat d'un Automower®<br />et d'une installation éffectuée par votre Revendeur</h3>        
      </div>-->
      <div class="modalites">
      <h1>OFFRE DE REMBOURSEMENT</h1>      
      <div class="modalites-bloc">
        <div>
         <img src="../assets/images/automower.jpg">
        </div>
        <div>
          <h2>Offre de Noël</h2>
          <p>Pour l’achat d’un robot tondeuse Automower® <!-- <a href="https://promo.husqvarna.com/fr/?dcomm_source=Site+Husqvarna&dcomm_medium=referral&dcomm_term=campaign-offers&dcomm_content=billboard-generique" target="_blank">sur la boutique en ligne</a> -->, Husqvarna vous rembourse jusqu’à 350€ sur les frais d’installation.  </p>
        </div>
      </div>
      <div class="comment">
      <h2>Comment bénéficier de l’offre ?</h2>
      <p class="intro">Du 14/12/2020 au 04/01/2021, jusqu’à 350€ remboursés pour l’achat d’un Automower<sup>®</sup> et d’une installation effectuée par votre Revendeur :</p>
      <p>
        200€ remboursés sur les frais d’installation sur le modèle AM 315X<br /><br />
        350€ remboursé sur les frais d’installation sur le modèle AM 430X
        <ul>
        <li>1. Acheter votre Automower® sur la boutique en ligne Husqvarna entre le 14/12/2020 et le 04/01/2021 parmi les 2 références éligibles à l’offre (AM 315X; AM 430X)</li>
        <li>2. Faites installer votre Automower® par votre Revendeur agréé Husqvarna entre le 14/12/2020 et le 28/02/2021 (voir liste des revendeurs agréés sur <a href="https://www.husqvarna.com/fr/localisation-revendeur" target="_blank">www.husqvarna.com/fr/localisation-revendeur</a>) </li>
        <li>3. Remplir intégralement le formulaire ci-dessous avant le 15/03/2021</li>
        <li>4. Veuillez à bien prendre connaissance des <a style="color:black" :href="`https://oxoformdocs.fra1.digitaloceanspaces.com/${game_id}/reglement.pdf`">modalités de l’offre</a>. Tout dossier incomplet ou non conforme ne sera pas accepté et ne donnera lieu à aucun remboursement. Aucune réclamation ne sera prise en compte après le 31/03/2021.</li>
        </ul>
      </p>
      <p>
        Avant de commencer à remplir le formulaire, nous vous demandons de vous munir des éléments suivants : 
      </p>
      <p>
        <ul>
          <li>1. La facture d’achat originale de votre Automower® sur la boutique en ligne Husqvarna datée entre le 14/12/2020 et le 04/01/2021</li>
          <li>2. La facture de l’installation de votre Automower® de votre Revendeur agréé Husqvarna datée entre le 14/12/2020 et le 28/02/2021</li>
          <li>3. Vos coordonnées bancaires : RIB / IBAN</li>
        </ul>
      </p>
      <br />
      <p>Sous réserve de conformité de votre dossier et à compter de la date d’acceptation du dossier, le remboursement sera effectué sur votre compte bancaire renseigné dans le formulaire relatif à l’offre (étape 3 « Vos coordonnées bancaires») sous 4 semaines maximum.</p>
      </div>
         <h2>Remplissez le formulaire ci-dessous :</h2>
      </div>  
         <Form :class="'form_step'+step"
            id="monform"
            :form_id="form_id"
            :server="server"
            v-on:results="getResult"
            v-on:step-up="checkStep"
            v-on:step-down="checkStep"
            v-on:form_submit_loading=loading
          />
   
    </div>
    <div class="captcha">
      This site is protected by reCAPTCHA and the Google<br />
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
    </div>

  </main>
</template>
<script>
var consts = require('../config');
import axios from "axios";

export default {
  name: "home",
  data: function () {
    return {
      step: 0,
      game_id : consts.GAME_ID,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },
  methods: {
    gohome() {
      this.$router.push("/");
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          allowOutsideClick: false,          
          title: 'Envoi en cours',
          html: 'Merci de bien vouloir patienter ...',
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading()
          }
        })
      }
    },
    checkStep() {
       this.step = this.step++
    },
    getResult(result) {
      if (result._id) {
        this.$swal.close()
        this.$router.push("/done");
      } else {
        alert('Erreur interne')
      }
    },
  },
  mounted() {},
  created: function () {}
} 
</script>
